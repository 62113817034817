<template>
  <div>
    <el-row class="hidden-xs-only">
      <el-col  style="margin-left: 2.37%;width:12.8%">
        <el-menu class="el-menu-vertical" :default-active="'/contact'" v-for="(item, i) in menuList" :key="i">
          <el-menu-item :index="'/' + item.path" @click="menuItemClick(i)">
            <span>{{ item.title }}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col style="width:11.4%">
        <el-image style="width: 100%; height: auto; margin-top: 7px;" :src="serverSrc+'/images/about/P1.png'"> </el-image>
      </el-col>
      <el-col style="padding-top: 18px;font-size: 14px;margin-right: 2.29%;width:71.1%">
        <div style="margin-bottom: 37px;margin-left: 14.6%;">
          <div >
            <div style="font-size:12px;margin-bottom: 15px">COMPANY NAME</div>
            <div style="margin-left: 58.9%;font-size: 14px;">CONNAIS TOI</div>
            <el-divider></el-divider>
          </div>
        </div>
        <div style="display: flex">
          <div style="width: 12.7%;text-align: right;margin-right: 1.9%;">[CT]</div>
          <div style="flex: 1">
            <div style="margin-bottom: 75px;line-height: normal;max-width: 250px;">
              A daring fashion showroom focusing on exploring the boundaries of aesthetics. We are inspired by the local urban characteristics, hot topics, and provide customers with a unique visual experience to present our distinctive character. Constantly engaging with customers and brands, we have a unique preference in choosing not only designer brands, but also in decoration. We aim to be le centre de la mode.
            </div>
            <div style="margin-bottom: 30px">
              <div style="width: 58.9%; display: inline-block">REPRESENTATIVE</div>
              <div style="display: inline-block">CYAN</div>
            </div>
            <div style="margin-bottom: 30px">
              <div style="width: 58.9%;display: inline-block">WEBSITE</div>
              <div style="display: inline-block">CONNAISTOIOFFICIAL.COM</div>
            </div>
            <div style="margin-bottom: 95px">
              <div style="width: 58.9%;display: inline-block">EMAIL</div>
              <div style="display: inline-block">INFO@CONNAISTOIOFFICIAL.COM</div>
            </div>
          </div>
        </div>
        <div style="display: flex;margin-bottom: 80px">
          <div style="width: 12.7%;text-align: right;margin-right: 1.9%;">[FR]</div>
          <div style="flex: 1">
            <div style="line-height: normal;">
              <div>Marchart dormant revant,</div>
              <div>la conscience ayant abandonné</div>
              <div>tout support,</div>
              <div>connais-toi en tant que présence</div>
              <div>lumineuse et spatiale.</div>
            </div>
          </div>
        </div>
        <div style="display: flex;margin-bottom: 101px">
          <div style="width: 12.7%;text-align: right;margin-right: 1.9%;">[EN]</div>
          <div style="flex: 1">
            <div style="line-height: normal;">
              <div>Waking,sleeping,dreaming.</div>
              <div>consciousness free from any prop,</div>
              <div>know yourself as radiant spatial presence.</div>
            </div>
          </div>
        </div>
        <div style="margin-bottom: 85px;margin-left: 14.6%;">
          <div >
            <div style="margin-bottom: 25px">SNS</div>
            <el-divider></el-divider>
          </div>
        </div>
        <div style="display: flex;margin-bottom: 30px">
          <div style="width: 12.7%;text-align: right;margin-right: 1.9%;">[1]</div>
          <div style="flex: 1">
            <div>
              <div style="width: 58.9%;display:inline-block">WEIBO</div>
              <div style="display:inline-block">CONNAOSTOI_OFFICIAL</div>
            </div>
          </div>
        </div>
        <div style="display: flex;margin-bottom: 30px">
          <div style="width: 12.7%;text-align: right;margin-right: 1.9%;">[2]</div>
          <div style="flex: 1">
            <div>
              <div style="width: 58.9%;display:inline-block">REDBOOK</div>
              <div style="display:inline-block">CONNAIS TOI</div>
            </div>
          </div>
        </div>
        <div style="display: flex;margin-bottom: 98px">
          <div style="width: 12.7%;text-align: right;margin-right: 1.9%;x">[3]</div>
          <div style="flex: 1">
            <div>
              <div style="width: 58.9%;display:inline-block">INSTAGRAM</div>
              <div style="display:inline-block">CONNAOSTOIOFFICIAL</div>
            </div>
          </div>
        </div>
        <div  style="margin-bottom: 58px;margin-left: 14.6%;">
          <div >
            <div style="margin-bottom: 27px">MAP</div>
            <el-divider></el-divider>
          </div>
        </div>
        <div style="display: flex;margin-bottom: 32px">
          <div style="width: 12.7%;text-align: right;margin-right: 1.9%;x">[AD]</div>
          <div style="flex: 1">
            <div>
              <div style="width: 58.9%; display:inline-block;float: left;">
                <div>ROOM 201, EXISTING PARK, YANPING ROAD,JING'AN DISTRICT,</div>
                <div>SHANGHAI, CHINA</div>
              </div>
              <div style="width: 41.1%;display:inline-block;">OPENING  11:00 AM / CLOSED  8:00 PM</div>
            </div>
          </div>
        </div>
        <div style="margin-bottom: 78px;margin-left: 14.6%;">
          <div >
            <div style="width:100%;height:549px;background-color: #ddd;"></div>
            <!-- <el-image style="width: 100%; height: auto" src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"> </el-image> -->
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="hidden-sm-and-up" style="padding: 45px 20px 20px;font-size: 14px">
      <el-col :span="24">
        <div style="font-size: 14px">
          <div style="font-size: 12px;margin-bottom: 15px">COMPANY NAME</div>
          <div style="font-size: 14px;" class="right">CONNAIS TOI</div>
          <el-divider></el-divider>
          <div style="margin-top: 22px;max-width: 250px;">
A daring fashion showroom focusing on exploring the boundaries of aesthetics. We are inspired by the local urban characteristics, hot topics, and provide customers with a unique visual experience to present our distinctive character. Constantly engaging with customers and brands, we have a unique preference in choosing not only designer brands, but also in decoration. We aim to be le centre de la mode.

          </div>
          <div  style="margin-top: 50px;font-weight: bold;">COMPAMY NAME</div>
          <div>CYAN</div>
          <div style="margin-top: 13px;font-weight: bold;">WEBSITE</div>
          <div>CONNAISTOIOFFICIAL.COM</div>
          <div style="margin-top: 13px">EMAIL</div>
          <div>INFO@CONNAISTOIOFFICIAL.COM</div>
          <div style="margin-top: 50px">Marchart dormant revant,</div>
          <div>la conscience ayant abandonné</div>
          <div>tout support,</div>
          <div>connais-toi en tant que présence</div>
          <div>lumineuse et spatiale.</div>
          <div style="margin-top: 50px">Waking,sleeping,dreaming.</div>
          <div>consciousness free from any prop,</div>
          <div>know yourself as radiant spatial presence.</div>
          <div style="margin: 52px 0 13px">SNS</div>
          <el-divider></el-divider>
          <div style="margin-top: 55px">
            <div style="display: inline-block">WEIBO</div>
            <div style="display: inline-block; float: right">CONNAOSTOI_OFFICIAL</div>
          </div>
          <div style="margin-top: 30px">
            <div style="display: inline-block">REDBOOK</div>
            <div style="display: inline-block; float: right">CONNAIS TOI</div>
          </div>
          <div style="margin-top: 30px">
            <div style="display: inline-block">INSTAGRAM</div>
            <div style="display: inline-block; float: right">CONNAOSTOIOFFICIAL</div>
          </div>
          <div style="margin: 50px 0 15px">MAP</div>
          <el-divider></el-divider>
          <div style="margin-top: 21px">
            <div style="display: inline-block; font-size: 12px">ROOM 201, EXISTING PARK, YANPING ROAD,</div>
            <div style="display: inline-block; font-size: 12px;">JING'AN DISTRICT, SHANGHAI, CHINA</div>
          </div>
          <div style="text-align: cente; margin-top: 11px">OPENING 11:00 AM / CLOSED 8:00 PM</div>
          <div style="margin: 17px 0 39px">
            <div style="width:100%;height:400px;background-color: #ddd;"></div>
            <!-- <el-image style="width: 100%; height: auto" src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"> </el-image> -->
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      serverSrc: '',
      menuList: []
    }
  },
  created () {
    this.getMenuList()
  },
  methods: {
    async getMenuList () {
      const { data: resData } = await this.$http.get('api/get_nav')
      const res = JSON.parse(resData)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.serverSrc = res.data.img_head_url
      this.menuList = res.data.nav
    },
    menuItemClick (index) {
      const item = this.menuList[index]
      console.log(item)
      if (item.type === 1) {
        this.$router.push('/' + item.path)
      } else if (item.type === 2) {
        this.$router.push({
          name: 'Show',
          query: {
            index: index
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-divider{
background-color: #333333;
}
.el-menu-vertical {
  background-color: unset;
  border: 0;
}
ul {
  list-style: none;
}
.box {
  display: flex;
}
.box .box-item {
  flex: 1;
  padding: 0px;
}
.box-item .box-item-image {
  width: 100%;
  height: auto;
}
.right {
  text-align: right;
}
.el-divider {
  margin: 0;
}
</style>
