<template>
  <div>
    <el-row class="hidden-xs-only">
      <el-col style="margin-left: 2.37%;width:12.8%;margin-right: 12.7%;">
        <el-menu class="el-menu-vertical" :default-active="'/brand'" v-for="(item, i) in menuList" :key="i">
          <el-menu-item :index="'/' + item.path" @click="menuItemClick(i)">
            <span>{{ item.title }}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col style="width:45%; margin-top: 7px">
        <div style="font-size: 12px">
          <div style="font-weight:bold;margin-bottom: 12px;font-size:20px">{{pageData.brand_detail.title}}</div>
          <div v-for="item in pageData.sub_brand" :key="item.id">
            <div style="cursor:pointer" @click="lookSubBrand(item.id)"> {{ item.title }}</div>
          </div>
          <div style="margin-top: 300px;font-size: 10px;white-space: pre">{{ pageData.brand_detail.text }}</div>
        </div>
      </el-col>
      <el-col style="width:24.8%;margin-right: 2.29%;" class="hidden-xs-only">
        <el-image class="carousel-image" :src="serverSrc + pageData.brand_detail.src"> </el-image>
      </el-col>
    </el-row>
    <el-row style="padding: 20px" class="hidden-sm-and-up">
      <el-col :xs="22" :sm="12" :md="10" :lg="8" :xl="6" :push="2" style="float: left; margin-top: 7px">
        <div style="font-size: 12px">
          <div style="font-weight:bold;margin-bottom: 12px;font-size:20px">{{pageData.brand_detail.title}}</div>
          <div v-for="item in pageData.sub_brand" :key="item.id">
            <div style="cursor:pointer" @click="lookSubBrand(item.id)"> {{ item.title }}</div>
          </div>
          <div style="margin-top: 10px;font-size: 10px;white-space: pre">{{ pageData.brand_detail.text }}</div>
        </div>
      </el-col>
    </el-row>
    <el-row class="hidden-sm-and-up" style="padding: 20px 0">
      <el-col style="float: right">
        <el-image class="carousel-image" :src="serverSrc + pageData.brand_detail.src" > </el-image>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      serverSrc: '',
      brand_id: this.$route.query.brand_id,
      menuList: [],
      pageData: {
        brand_detail: {},
        sub_brand: {}
      }
    }
  },
  created () {
    this.getMenuList()
    this.getPageData()
  },
  methods: {
    async getMenuList () {
      const { data: resData } = await this.$http.get('api/get_nav')
      const res = JSON.parse(resData)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.serverSrc = res.data.img_head_url
      this.menuList = res.data.nav
    },
    menuItemClick (index) {
      const item = this.menuList[index]
      console.log(item)
      if (item.type === 1) {
        this.$router.push('/' + item.path)
      } else if (item.type === 2) {
        this.$router.push({
          name: 'Show',
          query: {
            index: index
          }
        })
      }
    },
    async getPageData () {
      const { data: resData } = await this.$http.get('api/get_brand_detail', {
        params: { brand_id: this.brand_id }
      })
      const res = JSON.parse(resData)
      console.log(res)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.pageData.brand_detail = res.data.brand_detail
      this.pageData.sub_brand = res.data.sub_brand
      console.log(this.pageData)
    },
    lookSubBrand (id) {
      this.$router.push({
        name: 'Subbrand',
        query: {
          sub_brand_id: id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-menu-vertical {
  background-color: unset;
  border: 0;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul li {
  padding: 0 20px;
}
.brand-initials {
  width: 50%;
  float: left;
  margin-right: 50%;
}

.brand-name {
  display: block;
}
</style>
