<template>
  <div style="font-size: 13px;">
    <div class="hidden-xs-only">
      <el-row >
        <el-col style="margin-left: 2.37%;width:12.8%;margin-right: 12.7%;" class="hidden-xs-only">
        <el-menu class="el-menu-vertical" :default-active="'/brand'" v-for="(item, i) in menuList" :key="i">
          <el-menu-item :index="'/' + item.path" @click="menuItemClick(i)">
            <span>{{ item.title }}</span>
          </el-menu-item>
        </el-menu>
        </el-col>
        <el-col  style="margin-top: 7px;width:7.9%;margin-right: 10.4%;">
          <el-image style="width: 100%; height: auto" :src="serverSrc+'/images/brand/P1.png'"> </el-image>
          <div style="font-size: 12px;" class="text item">
            SHARE THE AESTHETIC JOY
            WITH YOU.
          </div>
        </el-col>
        <el-col style="margin-top: 7px;width:11.3%;">
          <div style="font-size: 14px;">A-Z</div>
        </el-col>
        <el-col style="margin-top: 7px;width:40.2%;margin-right: 2.29%;">
          <ul style="width:39%;float:left" v-if="pageData.letter_status===1">
            <li v-for="item in pageData.brand" :key="item.letter" style="padding:0">
              <div v-if="pageData.letter_status===1" class="brand-initials" style="width:39%">{{item.letter }}</div>
              <div class="brand-name"  style="width:61%" v-for="it in item.data" :key="it.id" @click="lookDetais(it.id)"><br></div>
            </li>
          </ul>
           <ul style="width:61%;float:left">
            <li v-for="item in pageData.brand" :key="item.letter" style="padding:0">
              <div class="brand-name"  style="width:61%" v-for="it in item.data" :key="it.id" @click="lookDetais(it.id)"> {{it.title}}</div>
              <br>
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <div class="hidden-sm-and-up">
      <el-row style="padding: 45px 20px 0;">
        <el-col :span="24" style="padding-left:20px">
          <div style="font-size: 14px;">A-Z</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col v-if="pageData.letter_status===0" :span="24" style="margin-top: 7px">
          <ul>
            <li v-for="item in pageData.brand" :key="item.letter">
              <div class="brand-name"  v-for="it in item.data" :key="it.id"  @click="lookDetais(it.id)"> {{it.title}} </div>
            </li>
          </ul>
        </el-col>
        <el-col v-if="pageData.letter_status===1" :span="24" style="margin-top: 7px">
          <ul>
            <li v-for="item in pageData.brand" :key="item.letter">
              <div class="brand-initials">{{item.letter }}</div>
              <div class="brand-name" style="margin-left: 50%;transform: translateY(-15px);"  v-for="it in item.data" :key="it.id"  @click="lookDetais(it.id)"> {{it.title}} </div>
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      serverSrc: '',
      menuList: [],
      pageData: {
        brand: [],
        letter_status: 0
      }
    }
  },
  created () {
    this.getMenuList()
    this.getPageData()
  },
  methods: {
    async getMenuList () {
      const { data: resData } = await this.$http.get('api/get_nav')
      const res = JSON.parse(resData)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.serverSrc = res.data.img_head_url
      this.menuList = res.data.nav
    },
    menuItemClick (index) {
      const item = this.menuList[index]
      if (item.type === 1) {
        this.$router.push('/' + item.path)
      } else if (item.type === 2) {
        this.$router.push({
          name: 'Show',
          query: {
            index: index
          }
        })
      }
    },
    async getPageData () {
      const { data: resData } = await this.$http.get('api/get_brand')
      const res = JSON.parse(resData)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.pageData.brand = res.data.brand
      this.pageData.letter_status = res.data.letter_status
    },
    lookDetais (id) {
      this.$router.push({
        name: 'Branddetail',
        query: {
          brand_id: id
        }
      })
    }

  }
}
</script>

<style lang="less" scoped>
.el-main .el-row:last-child {
  margin-bottom: 200px;
}
.el-menu-vertical {
  background-color: unset;
  border: 0;
}
ul {
  list-style: none;
  padding:0;
  margin: 0;
}
ul li {
  padding:0 20px;
}
.brand-initials {
  // width: 50%;
  // float: left;
  // margin-right: 50%;
  // font-size: 14px;
}

.brand-name {
  display: block;
  cursor:pointer
}
</style>
