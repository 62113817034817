<template>
  <div>
    <el-row class="hidden-xs-only">
      <el-col style="margin-left: 2.37%;width:12.8%">
        <el-menu class="el-menu-vertical" :default-active="'/news'" v-for="(item, i) in menuList" :key="i">
          <el-menu-item :index="'/' + item.path" @click="menuItemClick(i)">
            <span>{{ item.title }}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col style="width:21.8%">
          <div style=" width: 10%; float: left;font-size:13px;white-space: pre" >{{ pageData.text }}</div>
      </el-col>
      <el-col style="width:60.7%;margin-top: 7px;margin-right: 2.29%;">
        <div style="display: inline-block;">
          <ul>
            <li v-for="(item, i) in pageData.list" :key="i" class="box">
              <span class="box-item">
                <el-image class="box-item-image" :src="serverSrc + item"> </el-image>
              </span>
            </li>
          </ul>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" class="hidden-sm-and-up">
      <el-col :span="10">
        <div style="margin-top: 10px;font-size:13px;white-space: pre" >
          {{ pageData.text }}
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" class="hidden-sm-and-up">
      <el-col :span="24" style="margin-top: 5px">
        <ul>
          <li v-for="(item, i) in pageData.list" :key="i" class="box">
            <span class="box-item">
              <el-image class="box-item-image" :src="serverSrc + item"> </el-image>
              <div class="img-mark">{{ item.title }}</div>
            </span>
          </li>
        </ul>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      serverSrc: '',
      menuList: [],
      pageData: {
        text: '',
        list: []
      },
      news_id: this.$route.query.news_id
    }
  },
  created () {
    this.getMenuList()
    this.getPageData()
  },
  methods: {
    async getMenuList () {
      const { data: resData } = await this.$http.get('api/get_nav')
      const res = JSON.parse(resData)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.serverSrc = res.data.img_head_url
      this.menuList = res.data.nav
    },
    menuItemClick (index) {
      const item = this.menuList[index]
      if (item.type === 1) {
        this.$router.push('/' + item.path)
      } else if (item.type === 2) {
        this.$router.push({
          name: 'Show',
          query: {
            index: index
          }
        })
      }
    },
    async getPageData () {
      const { data: resData } = await this.$http.get('api/get_news_detail', {
        params: { news_id: this.news_id }
      })
      const res = JSON.parse(resData)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.pageData.list = res.data.news_img
      this.pageData.text = res.data.text
    }
  }
}
</script>

<style lang="less" scoped>
.el-menu-vertical {
  background-color: unset;
  border: 0;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.box {
  display: flex;
}
.box .box-item {
  flex: 1;
  padding: 0px;
}
.box-item .box-item-image {
  width: 100%;
  height: auto;
}
</style>
