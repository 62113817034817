<template>
  <div>
    <el-row class="hidden-xs-only">
      <el-col :span="2" style="margin-left: 2.37%; width: 12.8%">
        <el-menu class="el-menu-vertical" :default-active="'/about'" v-for="(item, i) in menuList" :key="i">
          <el-menu-item :index="'/' + item.path" @click="menuItemClick(i)">
            <span>{{ item.title }}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col style="width: 57.7%; letter-spacing: 1px">
        <div style="font-size: 12px; margin-top: 7px; width: 50%">
          <div>
            <div style="width: 30px; display: inline-block">01</div>
            <div style="display: inline-block">ABOUT.</div>
          </div>
          <div style="margin-left: 30px">
            <div style="letter-spacing: 0.8px; margin-top: 21px">
              A DARING FASHION SHOWROOM FOCUSING ON EXPLORING THE BOUNDARIES OF AESTHETICS. WE ARE INSPIRED BY THE LOCAL URBAN CHARACTERISTICS, HOT TOPICS, AND PROVIDE CUSTOMERS WITH A UNIQUE VISUAL
              EXPERIENCE TO PRESENT OUR DISTINCTIVE CHARACTER. CONSTANTLY ENGAGING WITH CUSTOMERS AND BRANDS, WE HAVE A UNIQUE PREFERENCE IN CHOOSING NOT ONLY DESIGNER BRANDS, BUT ALSO IN DECORATION.
              WE AIM TO BE LE CENTRE DE LA MODE.
            </div>
          </div>
          <div style="margin-top: 36px">
            <div style="width: 30px; display: inline-block">02</div>
            <div style="display: inline-block">SHOWROOM INFO.</div>
          </div>
          <div style="margin-left: 30px; margin-top: 16px">
            <div style="letter-spacing: 0.8px">ROOM 201, EXISTING PARK, YANPING ROAD, JING'AN DISTRICT, SHANGHAI, CHINA</div>
            <div style="margin-top: 21px">OPENING / CLOSED</div>
            <div>11:00AM-8:00PM</div>
          </div>
          <div style="margin-top: 36px">
            <div style="width: 30px; display: inline-block">03</div>
            <div style="display: inline-block">CONTACT.</div>
          </div>
          <div style="margin-left: 30px; margin-top: 11px">
            <div style="font-size: 10px; letter-spacing: 0.8px;">
              <div style="width:100px; display: inline-block;">PRESS:</div>
              <div style="display: inline-block;">press@connaistoiofficial.com</div>
            </div>
            <div style="font-size: 10px; letter-spacing: 0.8px;">
              <div style="width:100px; display: inline-block;">CUSTOMER：</div>
              <div style="display: inline-block;">customer@connaistoiofficial.com</div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col style="width: 24.8%; margin-right: 2.29%" v-if="pageData.carousel.length !== 0">
        <Carousel radius-dot="true" @on-change="carouselChange" :value="carouselIndex">
          <Carousel-item v-for="(item, i) in pageData.carousel" :key="i">
            <el-image class="carousel-image" :src="serverSrc + item.src" style="height: auto; width: 100%"> </el-image>
          </Carousel-item>
        </Carousel>
        <div style="float: right; margin-right: 5px; font-size: 12px">{{ pageData.carousel[carouselIndex].text1 }}</div>
      </el-col>
    </el-row>
    <el-row class="hidden-sm-and-up" style="padding: 20px; letter-spacing: 1px">
      <el-col>
        <div style="font-size: 12px">
          <div>
            <div style="width: 30px; display: inline-block">01</div>
            <div style="display: inline-block">ABOUT.</div>
          </div>
          <div style="margin-left: 30px; margin-top: 21px">
            <div>
              A DARING FASHION SHOWROOM FOCUSING ON EXPLORING THE BOUNDARIES OF AESTHETICS. WE ARE INSPIRED BY THE LOCAL URBAN CHARACTERISTICS, HOT TOPICS, AND PROVIDE CUSTOMERS WITH A UNIQUE VISUAL
              EXPERIENCE TO PRESENT OUR DISTINCTIVE CHARACTER. CONSTANTLY ENGAGING WITH CUSTOMERS AND BRANDS, WE HAVE A UNIQUE PREFERENCE IN CHOOSING NOT ONLY DESIGNER BRANDS, BUT ALSO IN DECORATION.
              WE AIM TO BE LE CENTRE DE LA MODE.
            </div>
          </div>
          <div style="margin-top: 36px">
            <div style="width: 30px; display: inline-block">02</div>
            <div style="display: inline-block">SHOWROOM INFO.</div>
          </div>
          <div style="margin-left: 30px; margin-top: 16px">
            <div>ROOM 201, EXISTING PARK, YANPING ROAD, JING'AN DISTRICT, SHANGHAI, CHINA</div>
            <div style="margin-top: 21px">OPENING / CLOSED</div>
            <div>11:00AM-8:00PM</div>
          </div>
          <div style="margin-top: 36px">
            <div style="width: 30px; display: inline-block">03</div>
            <div style="display: inline-block">CONTACT.</div>
          </div>
          <div style="margin-left: 30px; margin-top: 11px">
            <div style="font-size: 10px">
              PRESS: press@connaistoiofficial.com <br />
              CUSTOMER：customer@connaistoiofficial.com
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="hidden-sm-and-up">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="float: right">
        <Carousel radius-dot="true" autoplay="true" autoplay-speed="5000" v-if="pageData.carousel.length !== 0" @on-change="carouselChange" :value="carouselIndex">
          <Carousel-item v-for="(item, i) in pageData.carousel" :key="i">
            <el-image class="carousel-image" :src="serverSrc + item.src" style="height: 100%; width: 100%"> </el-image>
            <div style="float: right; margin-bottom: 5px; font-size: 12px">{{ item.text1 }}</div>
          </Carousel-item>
        </Carousel>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      serverSrc: '',
      menuList: [],
      pageData: {
        carousel: []
      },
      carouselIndex: 0
    }
  },
  created () {
    this.getMenuList()
    this.getPageData()
  },
  methods: {
    async getMenuList () {
      const { data: resData } = await this.$http.get('api/get_nav')
      const res = JSON.parse(resData)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.serverSrc = res.data.img_head_url
      this.menuList = res.data.nav
    },
    menuItemClick (index) {
      const item = this.menuList[index]
      if (item.type === 1) {
        this.$router.push('/' + item.path)
      } else if (item.type === 2) {
        this.$router.push({
          name: 'Show',
          query: {
            index: index
          }
        })
      }
    },
    async getPageData () {
      const { data: resData } = await this.$http.get('api/get_about')
      const res = JSON.parse(resData)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.pageData.carousel = res.data.list
    },
    carouselChange (oldValue, value) {
      this.carouselIndex = value
    }
  }
}
</script>

<style lang="less" scoped>
.el-main .el-row:last-child {
  margin-bottom: 200px;
}
.el-menu-vertical {
  background-color: unset;
  border: 0;
}
.el-carousel__container {
  height: auto !important;
}
.carousel-image {
  width: 100%;
  height: 100%;
}
.carousel-text {
  width: 100%;
  color: #303133;
  font-size: 12px;
  opacity: 0.75;
  margin: 20px 0 0;
}
</style>
