<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header style="height: unset;">
      <el-row class="hidden-sm-and-up header1" type="flex" justify="space-between" :span="24">
        <div v-if="menuList.length !== 0">{{ menuList[activePath].title }}</div>
        <div>
          <i class="el-icon-s-fold hidden-sm-and-up" @click="drawer = true"></i>
        </div>
      </el-row>
      <el-row :gutter="0" class="hidden-xs-only header2" type="flex" align="center" style="font-weight:100;">
        <!-- <el-col style="width:2.37%"></el-col> -->
        <el-col style="width:34.6%;margin-left: 2.37%"><div class="grid-content bg-purple" style="font-size: 24px;;font-weight: bold;margin-top: -4.5px;">BOUTIQUE STORE</div></el-col>
        <el-col style="width:35.9%"><div class="grid-content bg-purple-light" style="font-size: 12px">SHANGHAI, CHINA</div></el-col>
        <el-col style="width:24.8%;display: flex;-webkit-box-pack: justify;justify-content: space-between;-ms-flex-pack: justify;margin-right: 2.29%">
          <div class="grid-content bg-purple" style="font-size: 12px">ADDRESS</div>
          <div>
            <div class="grid-content bg-purple-light" style="font-size: 12px">ROOM 201,EXISTING PARK, YANPING ROAD,</div>
          <div class="grid-content bg-purple-light" style="font-size: 12px">JING'AN DISTRICT, SHANGHAI, CHINA</div>
          </div>
        </el-col>

        <!-- <el-col :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple" style="font-size: 12px">ADDRESS</div></el-col>
        <el-col :sm="8" :md="8" :lg="6" :xl="4">
          <div class="grid-content bg-purple-light" style="font-size: 12px">ROOM 201.EXISTING PARK,YANPING ROAD.</div>
          <div class="grid-content bg-purple-light" style="font-size: 12px">JING'AN DISTRICT .SHANGHGAI.CHINA</div>
        </el-col> -->
        <!-- <el-col style="width:2.29%"></el-col> -->
      </el-row>
      <el-row class="header3 hidden-xs-only" style="margin-top:68px">
        <el-col :span="24"><router-link  :to="'/home'"><img v-if="serverSrc !== '' "  fit="contain" :src="serverSrc+'/images/main/logo.png'" alt="" style="width: 100%; height: auto"  /></router-link></el-col>
      </el-row>
      <el-row class="header3 hidden-sm-and-up">
        <el-col :span="24"><router-link  :to="'/home'"><img v-if="serverSrc !== '' " fit="contain" :src="serverSrc+'/images/main/logo.png'" alt="" style="width: 100%; height: auto"  /></router-link></el-col>
      </el-row>
    </el-header>
    <!-- 主体页面区域 -->
    <el-main>
      <!-- 路由占位符区域 -->
      <router-view></router-view>
    </el-main>
    <!-- 底部区域 -->
    <el-footer>
      <div class="footer-main">
        <!-- <el-row>
          <el-col :span="24">
            <el-link class="footer-main-link" href="http://beian.miit.gov.cn/" target="_blank">网站备案号</el-link>
          </el-col>
        </el-row> -->
        <el-row>
          <el-col :span="24"><div class="grid-content bg-purple-dark">©CONNAIS TOI 2021</div></el-col>
        </el-row>
      </div>
    </el-footer>
    <el-backtop :bottom="100">
      <div class="el-backtop"><i class="el-icon-caret-top"></i></div>
    </el-backtop>
    <el-drawer title="我是标题" :visible.sync="drawer" :with-header="false" size="256px" >
      <el-menu class="el-menu-vertical" v-for="(item, i) in menuList" :key="i">
        <el-menu-item style="padding-left: 20px !important" :index="'' + i" @click="menuItemClick(i)">
          <span>{{ item.title }}</span>
        </el-menu-item>
      </el-menu>
    </el-drawer>
  </el-container>
</template>

<script>
export default {
  inject: ['reload'],
  data () {
    return {
      serverSrc: '',
      activePath: 0,
      menuList: [],
      drawer: false
    }
  },
  created () {
    this.getMenuList()
  },
  methods: {
    async getMenuList () {
      const { data: resData } = await this.$http.get('api/get_nav')
      const res = JSON.parse(resData)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.serverSrc = res.data.img_head_url
      this.menuList = res.data.nav
    },
    menuItemClick (index) {
      this.activePath = index
      const item = this.menuList[index]
      this.drawer = false
      if (item.type === 1) {
        this.$router.push('/' + item.path)
      } else if (item.type === 2) {
        this.reload()
        this.$router.push({
          name: 'Show',
          query: {
            index: index
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-drawer__body{
  padding-left:20px;
}
.el-drawer .el-menu-item.is-active span{
   border-bottom: 0;
}
.home-container {
  // min-width: 1200px;
}
.el-header .header1 {
  background-color: #101010;
  color: #fff;
  height: 68px;
  line-height: 68px;
  font-size: 16px;
  padding: 0 10px;
}
.el-header .header1 div{
  display: table-cell;
  height: 100px;
  vertical-align: middle;
}
.el-header .header1 i {
  line-height: 68px;
  font-size: 30px;
  color: #fff;
}
.el-header .header2 {
  // height: 60px;
  // align-items: center;
  color: #000006;
  font-size: 20px;
  position: absolute;
  width: 100%;
  top: 64px;
}
.el-header .header3 .el-col {
  // height: 100px;
}
.footer-main {
  padding: 16px 0;
  font-size: 12px;
  text-align: center;
}
.footer-main-link {
  color: unset;
}
.el-backtop{
  color: #000000;
  box-shadow:none;
  -webkit-box-shadow:none;
  background-color:unset;
}
</style>
