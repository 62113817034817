<template>
  <div>
    <el-row type="flex" justify="space-between" class="hidden-xs-only">
      <el-col :span="2" class="hidden-xs-only" style="margin-left: 2.37%">
        <el-menu class="el-menu-vertical" :default-active="'/showRoom'" v-for="(item, i) in menuList" :key="i">
          <el-menu-item :index="'/' + item.path" @click="menuItemClick(i)">
            <span>{{ item.title }}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col  style="width: 60.7%;margin-right: 2.29%;">
        <el-image class="box-item-image" :src="serverSrc + bigSrc" lazy> </el-image>
      </el-col>
    </el-row>
    <el-row class="hidden-sm-and-up" type="flex" justify="center">
      <el-col :span="24">
        <el-image class="box-item-image" :src="serverSrc + bigSrc" lazy> </el-image>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      serverSrc: '',
      bigSrc: this.$route.query.bigSrc,
      menuList: []
    }
  },
  created () {
    this.getMenuList()
  },
  methods: {
    async getMenuList () {
      const { data: resData } = await this.$http.get('api/get_nav')
      const res = JSON.parse(resData)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.serverSrc = res.data.img_head_url
      this.menuList = res.data.nav
    },
    menuItemClick (index) {
      const item = this.menuList[index]
      if (item.type === 1) {
        this.$router.push('/' + item.path)
      } else if (item.type === 2) {
        this.$router.push({
          name: 'Show',
          query: {
            index: index
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-menu-vertical {
  background-color: unset;
  border: 0;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.box-item-image{
    width: 100%;
    height: auto;
}

</style>
