<template>
  <div>
    <el-row style="display:flex;padding:45px 20px" class="hidden-xs-only">
      <el-col :span="2" class="hidden-xs-only">
       <el-menu class="el-menu-vertical" :default-active="activePath" v-for="(item, i) in menuList" :key="i">
          <el-menu-item v-if="item.type==1" :index="'/' + item.path" @click="menuItemClick(i)">
            <span>{{ item.title }}</span>
          </el-menu-item>
          <el-menu-item v-if="item.type==2" :index="'/show'+i" @click="menuItemClick(i)">
            <span>{{ item.title }}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16" style="flex:1;text-align:center">
        <el-image class="box-item-image" :src="src"> </el-image>
      </el-col>
    </el-row>
    <el-row style="padding:15px 0" class="hidden-sm-and-up" type="flex" justify="center">
      <el-col :span="24" style="text-align:center">
        <el-image class="box-item-image" :src="src"> </el-image>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      serverSrc: this.GLOBAL.serverSrc,
      menuList: [],
      src: '',
      activePath: '/show' + this.$route.query.index
    }
  },
  created () {
    this.getMenuList()
    console.log(this.$route.query)
  },
  methods: {
    change () {
      console.log('change')
    },
    async getMenuList () {
      console.log('getMenuList')
      const { data: resData } = await this.$http.get('api/get_nav')
      const res = JSON.parse(resData)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.serverSrc = res.data.img_head_url
      this.menuList = res.data.nav
      if (res.data[this.$route.query.index].src !== null) {
        this.src = this.serverSrc + res.data[this.$route.query.index].src
      }
    },
    menuItemClick (index) {
      const item = this.menuList[index]
      if (item.type === 1) {
        this.$router.push('/' + item.path)
      } else if (item.type === 2) {
        this.$router.push({
          name: 'Show',
          query: {
            index: index
          }
        })
        this.activePath = '/show' + index
        this.src = this.serverSrc + this.menuList[index].src
      }
    }

  },
  updated () {
    console.log('updated')
  }
}
</script>

<style lang="less" scoped>
.el-menu-vertical {
  background-color: unset;
  border: 0;
}
ul {
  list-style: none;
}
.box {
  display: flex;
}
.box .box-item {
  flex: 1;
  padding: 0px;
}
.box-item .box-item-image {
  width: 100%;
  height: auto;
}
</style>
