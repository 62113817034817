<template>
  <div  @mouseleave="leave()" @mouseover="over()">
    <video ref="videoPlayer" class="video-js" ></video>
  </div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/video.js/7.16.0/video.min.js"></script>
<script>
export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      player: null,
      className: ''
    }
  },
  mounted () {
    this.player = this.$video(this.$refs.videoPlayer, this.options, function onPlayerReady () {
      // console.log('onPlayerReady', this)
    })
  },
  beforeDestroy () {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    leave () {
      // console.log('leave')
       this.$refs.videoPlayer.nextSibling.nextSibling.nextSibling.nextSibling.nextSibling.style.display = 'none'
    },
    over () {
      // console.log('over')
      this.$refs.videoPlayer.nextSibling.nextSibling.nextSibling.nextSibling.nextSibling.style.display = 'flex'
    }
  }
}
</script>
