import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import Index from '../components/Index.vue'
import About from '../components/about/About.vue'
import Showroom from '../components/showroom/Showroom.vue'
import News from '../components/news/News.vue'
import Detail from '../components/news/Detail.vue'
import Contact from '../components/contact/Contact.vue'
import Brand from '../components/brand/Brand.vue'
import Show from '../components/Show.vue'
import Gallery from '../components/gallery/Gallery.vue'
import Roomdetail from '../components/showroom/Roomdetail.vue'
import Branddetail from '../components/brand/Branddetail.vue'
import Subbrand from '../components/brand/Subbrand.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'Index',
        component: Index
      },
      {
        path: '/about',
        name: 'About',
        component: About
      },
      {
        path: '/showroom',
        name: 'Showroom',
        component: Showroom
      },
      {
        path: '/roomdetail',
        name: 'Roomdetail',
        component: Roomdetail
      },
      {
        path: '/news',
        name: 'News',
        component: News
      },
      {
        path: '/detail',
        name: 'Detail',
        component: Detail
      },
      {
        path: '/contact',
        name: 'Contact',
        component: Contact
      },
      {
        path: '/brand',
        name: 'Brand',
        component: Brand
      },
      {
        path: '/branddetail',
        name: 'Branddetail',
        component: Branddetail
      },
      {
        path: '/subbrand',
        name: 'Subbrand',
        component: Subbrand
      },
      {
        path: '/show',
        name: 'Show',
        component: Show
      },
      {
        path: '/gallery',
        name: 'Gallery',
        component: Gallery
      }
    ]
  },
  { path: '/', redirect: '/home' }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  // 通过该方式保持VueRouterd数据不被刷新
  localStorage.setItem('routerParams', JSON.stringify(to.params))
  next()
})
export default router
