<template>
  <div v-if="serverSrc !== ''">
    <el-row type="flex" justify="space-between" class="hidden-xs-only">
      <el-col :xs="12" :sm="6" :md="2" :lg="2" :xl="2" style="margin-left: 2.37%">
        <el-menu class="el-menu-vertical" :default-active="'/index'" v-for="(item, i) in menuList" :key="i">
          <el-menu-item :index="'/' + item.path" @click="menuItemClick(i)">
            <span>{{ item.title }}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col class="hidden-xs-only" style="text-align: center; width: 24.84%; margin-right: 2.29%">
        <Carousel radius-dot="true" autoplay="true" autoplay-speed='5000' v-if=" pageData.carousel !== ''"  @on-change="carouselChange" :value="carouselIndex">
          <Carousel-item v-for="(item, i) in pageData.carousel" :key="i" >
            <div style="float: right; margin-bottom: 5px; font-size: 12px">{{item.text1}}</div>
            <a :href="item.url" target="_blank" >
            <el-image class="carousel-image" :src="serverSrc + item.src" style="height: 100%; width: 100%" > </el-image>
            </a >
          </Carousel-item>
        </Carousel>
        <div class="carousel-text" style="text-align: left; font-size: 12px;hyphens: auto;text-align: justify;">
         A daring fashion showroom focusing on exploring the boundaries of aesthetics. We are inspired by the local urban characteristics, hot topics, and provide customers with a unique visual experience to present our distinctive character. Constantly engaging with customers and brands, we have a unique preference in choosing not only designer brands, but also in decoration. We aim to be le centre de la mode.
        </div>
      </el-col>
    </el-row>
    <el-row class="hidden-sm-and-up" type="flex" justify="center">
      <el-col class="hidden-sm-and-up">
         <Carousel radius-dot="true" autoplay="true" autoplay-speed='5000' v-if=" pageData.carousel !== ''" @on-change="carouselChange" :value="carouselIndex">
          <Carousel-item v-for="(item, i) in pageData.carousel" :key="i" >
            <div style="float: right; margin-bottom: 5px; font-size: 12px">{{pageData.carousel[carouselIndex].text1}}</div>
            <a :href="item.url" target="_blank" >
            <el-image class="carousel-image" :src="serverSrc + item.src" style="height: 100%; width: 100%" > </el-image>
            </a >
          </Carousel-item>
        </Carousel>
        <div class="carousel-text" style="padding: 0 15px; font-size: 12px;hyphens: auto;text-align: justify;">
          A daring fashion showroom focusing on exploring the boundaries of aesthetics. We are inspired by the local urban characteristics, hot topics, and provide customers with a unique visual experience to present our distinctive character. Constantly engaging with customers and brands, we have a unique preference in choosing not only designer brands, but also in decoration. We aim to be le centre de la mode.
        </div>
      </el-col>
    </el-row>
    <el-row class="hidden-xs-only" style="margin-top: 182px" type="flex" justify="space-between">
      <el-col style="width: 720px; height: 600px;margin-left: 2.37%">
        <el-image style="width: 400px; height: 600px; float: left" v-if="pageData.img1 !== ''" :lazy="true" :src="serverSrc + pageData.img1"> </el-image>
        <div style="width: 300px; height: 600px; margin-left: 20px; font-size: 12px; position: relative; float: right">
          <div style="position: absolute; bottom: 0px; letter-spacing: 1.2px">
            <div style="font-size: 16px; margin-bottom: 17px; letter-spacing: 1.3px; font-weight: bold">SLOGAN</div>
            <div style="font-size: 12px">IMMEASURABLE/UNBOUNDED FORM</div>
            <div style="font-size: 12px">CEASELESS ALLURE</div>
            <div style="font-size: 12px">UNENDING YOU</div>
            <div style="font-size: 12px">BE THE FORM,BE THE ALLURE, BE YOU.</div>
          </div>
        </div>
      </el-col>
      <el-col style="width: 200px; height: 300px;margin-right: 2.29%" >
        <div style="margin-bottom: 12px; font-size: 18px;display:flex;justify-content: space-between;">
          <div>BOUTIQUE</div>
          <div>STORE</div>
        </div>
        <el-image style="width: 100%; height: 100%" :src="serverSrc + pageData.img1"> </el-image>
        <div style="margin-top: 10px; font-size: 13px">SINCE 2017</div>
      </el-col>
    </el-row>
    <el-row class="hidden-sm-and-up" style="margin: 20px" type="flex" justify="space-between">
      <el-col :span="15">
        <el-image fit="contain" v-if="pageData.img1 !== ''" :lazy="true" :src="serverSrc + pageData.img1"> </el-image>
      </el-col>
      <el-col :span="8" style="right: 0">
        <div style="position: absolute; bottom: 0px; letter-spacing: 1.2px;color: black">
          <div style="font-size: 16px; margin-bottom: 17px; letter-spacing: 1.3px; font-weight: bold">SLOGAN</div>
          <div style="font-size: 12px">IMMEASURABLE / UNBOUNDED FORM</div>
          <div style="font-size: 12px">CEASELESS ALLURE</div>
          <div style="font-size: 12px">UNENDING YOU</div>
          <div style="font-size: 12px">BE THE FORM,BE THE ALLURE,BE YOU.</div>
        </div>
      </el-col>
    </el-row>
    <el-row class="hidden-sm-and-up" style="padding: 20px" type="flex" justify="center">
      <el-col :span="8">
        <div style="margin-bottom: 12px; font-size: 18px;display:flex;justify-content: space-between;">
          <div>BOUTIQUE</div>
          <div>STORE</div>
        </div>
        <el-image fit="contain" :src="serverSrc + pageData.img1"> </el-image>
        <div style="margin-top: 10px; font-size: 13px">SINCE 2017</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col class="hidden-xs-only" style="margin:186px 2.29% 0 2.37%;width:95.34%">
        <video-player v-if="videoOptions !== null" :options="videoOptions" />
        <div style="margin: 20px 0; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; display: flex">
          <div style="font-size: 20px;">GOODS</div>
          <div style="font-size: 12px;">CONNAIS TOI PRESENTS</div>
        </div>
      </el-col>
    </el-row>
    <el-row class="hidden-sm-and-up" style="margin-top: 0px">
      <el-col :span="24">
        <video-player v-if="videoOptions !== null" :options="videoOptions" />
        <div style="margin-top: 1px; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; display: flex">
          <div style="font-size: 14px;">GOODS</div>
          <div style="font-size: 12px;">CONNAIS TOI PRESENTS</div>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 184px" justify="center" align="middle" type="flex">
      <el-col :xs="18" :sm="18" :md="16" :lg="12" :xl="10" style="text-align: center; font-size: 14px; letter-spacing: 2px;color: black;">
        <div style="font-size: 20px; color: #101010; margin-bottom: 28px">ROOM.</div>
        <div>Kahlil Gibrans“The Prophet”</div>
        <div>“And stand together,yet not too near together.For the pillars of the temple stand apart,</div>
        <div style="margin-bottom: 72px">And the oak tree and the cypress grow not in each other's shadow.”</div>
      </el-col>
    </el-row>
    <el-row justify="center" align="middle" type="flex">
      <el-col :xs="20" :sm="14" :md="12" :lg="8" :xl="6" style="text-align: center; margin-bottom: 63px; display: flex">
        <el-image style="flex: 1" :lazy="true" :src="serverSrc + '/images/main/P7.png'"> </el-image>
        <div style="width: 20px; text-align: end; writing-mode: vertical-lr">CONNAIS TOI.2017-NOW</div>
      </el-col>
    </el-row>
    <el-row justify="center" align="middle" type="flex" style="margin-bottom: 193px">
      <el-col :xs="24" :sm="14" :md="12" :lg="8" :xl="6" style="text-align: center; font-weight: bold; letter-spacing: 2px;color: black;">
        <div>ROOM 201,EXISTING PARK,YANPING ROAD,</div>
        <div>JING'AN DISTRICT,SHANGHAI,CHINA</div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import VideoPlayer from '@/components/VideoPlayer.vue'
export default {
  name: 'VideoExample',
  components: {
    VideoPlayer
  },
  data () {
    return {
      serverSrc: '',
      menuList: [],
      pageData: {
        img1: '',
        img2: '',
        video: '',
        carousel: []
      },
      videoOptions: null,
      carouselIndex: 0
    }
  },
  created () {
    this.getMenuList()
    this.getPageData()
  },
  methods: {
    async getMenuList () {
      const { data: resData } = await this.$http.get('api/get_nav')
      const res = JSON.parse(resData)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.serverSrc = res.data.img_head_url
      this.menuList = res.data.nav
    },
    async getPageData () {
      const { data: resData } = await this.$http.get('api/get_index')
      const res = JSON.parse(resData)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.pageData.img1 = res.data.img1
      this.pageData.img2 = res.data.img2
      // this.videoOptions.sources[0].src = res.data.video
      // this.videoOptions.poster = this.GLOBAL.serverSrc + res.data.video_poster
      this.pageData.carousel = res.data.carousel.list
      const videoOptions = {
        controls: true,
        poster: this.GLOBAL.serverSrc + res.data.video_poster, // 视频封面图地址
        preload: 'auto',
        autoplay: true,
        loop: true,
        fluid: true, // 自适应宽高
        language: 'en', // 设置语言
        muted: true, // 是否静音
        inactivityTimeout: false,
        pictureInPictureToggle: false,
        customControlSpacer: true,
        controlBar: {
          children: [
            { name: 'playToggle' }, // 播放按钮
            { name: 'currentTimeDisplay' },
            { name: 'timeDivider' },
            { name: 'durationDisplay' },
            { name: 'progressControl' }, // 播放进度条
            {
              name: 'volumePanel', // 音量控制
              inline: false // 不使用水平方式
            },
            { name: 'FullscreenToggle' } // 全屏
          ]
        },
        sources: [
          {
            src: this.GLOBAL.serverSrc + res.data.video,
            type: 'video/mp4'
          }
        ]
      }
      this.videoOptions = videoOptions
    },
    menuItemClick (index) {
      const item = this.menuList[index]
      if (item.type === 1) {
        this.$router.push('/' + item.path)
      } else if (item.type === 2) {
        this.$router.push({
          name: 'Show',
          query: {
            index: index
          }
        })
      }
    },
    carouselChange (oldValue, value) {
      this.carouselIndex = value
    }
  }
}
</script>
<style lang="less" scoped>
.img1 img {
  width: auto;
}

.my-video {
  width: 100%;
  height: auto;
}
.el-main .el-row:last-child {
  margin-bottom: 200px;
}
.el-menu-vertical {
  background-color: unset;
  border: 0;
}
.el-carousel__container {
  height: 577px !important;
}
.carousel-image {
  width: 100%;
  height: 100%;
}
.carousel-text {
  color: #303133;
  font-size: 12px;
  opacity: 0.75;
  margin: 20px 0 0;
}

.footer-main-link {
  color: unset;
}
//视频样式调整
/deep/ .video-js.vjs-playing {
  cursor: url(../assets/images/main/pause.png), pointer;
}
/deep/ .video-js.vjs-paused {
  cursor: url(../assets/images/main/play.png), pointer;
}
/deep/ .video-js .vjs-control-bar {
  background-color: #eaedf1 !important;
  color: #000;
  height: 5em;
  font-weight: 100;
  // cursor: default;
}
/deep/ .video-js .vjs-slider {
  background-color: #999;
  height: 0.01em;
  z-index: 5;
}
/deep/ .video-js .vjs-play-progress:before {
  top: -0.7em;
  content: '\007c';
  right: -0.1em;
}
/deep/ .video-js .vjs-play-control .vjs-icon-placeholder:before {
  // content: '播放';
  font-weight: 100;
  font-size: x-large;
  line-height: 2em;
}
/deep/ .video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before {
  // content: '暂停';
  font-weight: 100;
  font-size: x-large;
  line-height: 2em;
}
/deep/ .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {
  // content: '全屏';
  font-weight: 100;
  font-size: x-large;
  line-height: 2em;
}
/deep/ .video-js .vjs-picture-in-picture-control {
  display: none;
}
/deep/ .vjs-mouse-display:hover {
  font-size: 12px;
  line-height: 12px;
}
/deep/ .video-js .vjs-mute-control .vjs-icon-placeholder:before {
  font-size: 2em;
  line-height: 2.5;
}
/deep/ .video-js .vjs-mouse-display .vjs-time-tooltip {
  background-color: unset;
  color: unset;
  top: -2em;
}
/deep/ .video-js .vjs-play-progress .vjs-time-tooltip {
  display: none !important;
}
/* Time Display
--------------------------------------------------------------------------------
*/
/deep/ .video-js .vjs-time-control {
  font-size: 1em;
  /* Align vertically by making the line height the same as the control bar */
  line-height: 5em;
}
/deep/ .video-js .vjs-current-time {
  float: left;
  display: block;
}
/deep/ .video-js .vjs-duration {
  float: left;
  display: block;
  padding-left: 0;
}
/* Remaining time is in the HTML, but not included in default design */
/deep/ .vjs-remaining-time {
  display: none;
  float: left;
}
/deep/ .video-js .vjs-time-divider {
  float: left;
  display: block;
  padding: 0;
  min-width: 1em;
}
/deep/ .video-js .vjs-current-time {
  display: block;
}
/deep/ .video-js .vjs-volume-panel {
  cursor: pointer;
  float: right;
  width: 5em;
}
/deep/ .video-js .vjs-volume-panel .vjs-mute-control {
  width: 5em;
}
/deep/ .video-js .vjs-volume-panel.vjs-volume-panel-vertical {
  width: 5em;
}
/deep/ .video-js .vjs-volume-panel .vjs-volume-control {
  display: none !important;
}
/deep/ .video-js .vjs-volume-panel.vjs-control.vjs-volume-panel-horizontal.vjs-hover {
  width: 5em;
}
/deep/ .video-js .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder:before {
  // content: '开启声音';
  font-size: x-large;
  line-height: 2em;
}
/deep/ .video-js .vjs-mute-control .vjs-icon-placeholder:before {
  // content: '关闭声音';
  font-size: x-large;
  line-height: 2em;
}
/deep/ .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
