import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import './plugins/element.js'

// 导入全局样式
import './assets/css/global.css'
import 'element-ui/lib/theme-chalk/display.css'
import 'video.js/dist/video-js.css'
import videojs from 'video.js'
import global_ from './components/Global.vue'

import axios from 'axios'
// 配置请求的根路径
axios.defaults.baseURL = 'https://admin.connaistoiofficial.com/'

Vue.prototype.$http = axios
Vue.prototype.GLOBAL = global_
Vue.prototype.$video = videojs
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
