<template>
  <div style="font-size:12px;">
    <el-row  class="hidden-xs-only">
      <el-col style="margin-left: 2.37%;width:12.8%;margin-right: 12.7%;" >
        <el-menu class="el-menu-vertical" :default-active="'/brand'" v-for="(item, i) in menuList" :key="i">
          <el-menu-item :index="'/' + item.path" @click="menuItemClick(i)">
            <span>{{ item.title }}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col style="width:9.1%">
        <div style="margin-bottom: 18px;cursor:pointer"  @click="backBranddetail(pageData.brand_detail.id)">
          <div style="font-weight:bold;font-size:23px;">{{ pageData.brand_detail.title }}</div>
          <br>
          <div >{{ pageData.sub_brand_detail.title }}</div>
        </div>
      </el-col>
      <el-col style="width:60.7%;margin-top: 7px;margin-right: 2.29%">
        <div style="display: inline-block;">
          <ul>
            <li class="box">
              <span v-for="(item, i) in pageData.sub_brand_img" :key="i" class="box-item">
                <el-image class="box-item-image" v-if="serverSrc !== ''"  :src="serverSrc + item" lazy> </el-image>
              </span>
            </li>
          </ul>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" class="hidden-sm-and-up" style="padding: 20px 0 0">
      <el-col :span="20">
        <div style="margin-top: 10px; font-size: 12px;cursor:pointer"  @click="backBranddetail(pageData.brand_detail.id)">
          <div style="font-weight:bold;font-size:23px;">{{ pageData.brand_detail.title }}</div>
          <br>
          <div >{{ pageData.sub_brand_detail.title }}</div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" class="hidden-sm-and-up" style="padding: 20px 0 0">
      <el-col :span="24" style="margin-top: 5px">
        <ul>
          <li class="box">
            <span v-for="(item, i) in pageData.sub_brand_img" :key="i" class="box-item">
              <el-image class="box-item-image" v-if="serverSrc !== ''" :src="serverSrc + item" lazy> </el-image>
            </span>
          </li>
        </ul>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      serverSrc: '',
      sub_brand_id: this.$route.query.sub_brand_id,
      menuList: [],
      pageData: {
        sub_brand_img: [],
        brand_detail: {},
        sub_brand_detail: {}
      }
    }
  },
  created () {
    this.getMenuList()
    this.getPageData()
  },
  methods: {
    async getMenuList () {
      const { data: resData } = await this.$http.get('api/get_nav')
      const res = JSON.parse(resData)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.serverSrc = res.data.img_head_url
      this.menuList = res.data.nav
    },
    menuItemClick (index) {
      const item = this.menuList[index]
      console.log(item)
      if (item.type === 1) {
        this.$router.push('/' + item.path)
      } else if (item.type === 2) {
        this.$router.push({
          name: 'Show',
          query: {
            index: index
          }
        })
      }
    },
    async getPageData () {
      const { data: resData } = await this.$http.get('api/get_sub_brand', {
        params: { sub_brand_id: this.sub_brand_id }
      })
      const res = JSON.parse(resData)
      console.log(res)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.pageData.sub_brand_img = res.data.sub_brand_img
      this.pageData.brand_detail = res.data.brand_detail
      this.pageData.sub_brand_detail = res.data.sub_brand_detail
      console.log(this.pageData)
    },
    backBranddetail (id) {
      this.$router.push({
        name: 'Branddetail',
        query: {
          brand_id: id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-menu-vertical {
  background-color: unset;
  border: 0;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul li {
  padding: 0 20px;
}
.box {
  text-align: center;
  display: flex;
  align-content: flex-start;
  flex-flow: row wrap;
  padding: 0;
}
.box .box-item {
  display: inline-block;
  flex: 0 0 33%;
}
</style>
