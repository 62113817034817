<template>
  <div id="app">
    <!-- 路由占位符 -->
    <router-view v-if="RouterState"></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      RouterState: true
    }
  },
  methods: {
    goBack () {

    },
    reload () {
      console.log('reload')
      this.RouterState = false
      this.$nextTick(() => {
        this.RouterState = true
      })
    }
  }
}
</script>

<style>
</style>
