<template>
  <div>
    <el-row type="flex" justify="space-between" class="hidden-xs-only">
      <el-col  style="margin-left: 2.37%;width:12.8%">
        <el-menu class="el-menu-vertical" :default-active="'/gallery'" v-for="(item, i) in menuList" :key="i">
          <el-menu-item :index="'/' + item.path" @click="menuItemClick(i)">
            <span>{{ item.title }}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col style="width:60.7%;margin-top: 7px;margin-right: 2.29%;">
         <el-image class="box-item-image" :src="serverSrc +'/images/gallery/P1.png'" > </el-image>
         <div style="text-align:right" >COMING SOON</div>
      </el-col>
    </el-row>
    <el-row style="padding: 25px 0px 0" class="hidden-sm-and-up">
      <el-col :span="24" style="margin-top: 7px;">
         <el-image class="box-item-image" :src="serverSrc +'/images/gallery/P1.png'" > </el-image>
         <div style="text-align:right;padding-right: 5px;" >COMING SOON</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      serverSrc: '',
      menuList: []
    }
  },
  created () {
    this.getMenuList()
  },
  methods: {
    async getMenuList () {
      const { data: resData } = await this.$http.get('api/get_nav')
      const res = JSON.parse(resData)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.serverSrc = res.data.img_head_url
      this.menuList = res.data.nav
    },
    menuItemClick (index) {
      const item = this.menuList[index]
      if (item.type === 1) {
        this.$router.push('/' + item.path)
      } else if (item.type === 2) {
        this.$router.push({
          name: 'Show',
          query: {
            index: index
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-main .el-row:nth-child(n + 2) {
  padding-top: 200px;
}
.el-main .el-row:last-child {
  margin-bottom: 200px;
}
.el-menu-vertical {
  background-color: unset;
  border: 0;
}
</style>
