<template>
  <div>
    <el-row class="hidden-xs-only">
      <el-col :span="2" style="margin-left: 2.37%;width:12.8%">
        <el-menu class="el-menu-vertical" :default-active="'/news'" v-for="(item, i) in menuList" :key="i">
          <el-menu-item :index="'/' + item.path" @click="menuItemClick(i)">
            <span>{{ item.title }}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col style="width:11.4%">
        <div style="margin-top: 5px; font-size: 12px" >
          <div>2021</div>
          <div>SHANGHAI,CHINA</div>
          <br />
          <div>WALLPAPER</div>
          <div>ELLE DECORATION HOTSPOT</div>
        </div>
      </el-col>
      <el-col style="width:51.7%;margin-right: 2.29%;margin-top: 5px; float: right" class="hidden-xs-only">
        <ul>
          <li v-for="item in pageData.list" :key="item.id" class="box">
            <span class="box-item">
              <el-image class="box-item-image" :src="serverSrc + item.src" @click="lookDetais(item.id)"> </el-image>
              <div class="img-mark">{{ item.title }}</div>
            </span>
          </li>
        </ul>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[4]"
          :page-size="4"
          layout="prev, pager, next"
          :total="pageData.total"
          :hide-on-single-page="true"
        >
        </el-pagination>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" class="hidden-sm-and-up">
      <el-col :span="20">
        <div style="margin-top: 10px; font-size: 12px" >
          <div>2021</div>
          <div>SHANGHAI,CHINA</div>
          <br />
          <div>WALLPAPER</div>
          <div>ELLE DECORATION HOTSPOT</div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" class="hidden-sm-and-up">
      <el-col :span="24" style="margin-top: 5px">
        <ul>
          <li v-for="item in pageData.list" :key="item.id" class="box">
            <span class="box-item">
              <el-image class="box-item-image" :src="serverSrc + item.src" @click="lookDetais(item.id)"> </el-image>
              <div class="img-mark">{{ item.title }}</div>
            </span>
          </li>
        </ul>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[4]"
          :page-size="4"
          layout="prev, pager, next"
          :total="pageData.total"
          :hide-on-single-page="true"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      serverSrc: '',
      menuList: [],
      pageData: {
        page_sum: 1,
        total: 0,
        list: [],
        text: ''
      },
      currentPage: 1
    }
  },
  created () {
    this.getMenuList()
    this.getPageData()
  },
  methods: {
    async getMenuList () {
      const { data: resData } = await this.$http.get('api/get_nav')
      const res = JSON.parse(resData)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.serverSrc = res.data.img_head_url
      this.menuList = res.data.nav
    },
    lookDetais (id) {
      this.$router.push({
        name: 'Detail',
        query: {
          news_id: id
        }
      })
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.getPageData()
    },
    menuItemClick (index) {
      const item = this.menuList[index]
      if (item.type === 1) {
        this.$router.push('/' + item.path)
      } else if (item.type === 2) {
        this.$router.push({
          name: 'Show',
          query: {
            index: index
          }
        })
      }
    },
    async getPageData () {
      const { data: resData } = await this.$http.get('api/get_news', {
        params: { page: this.currentPage }
      })
      const res = JSON.parse(resData)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.pageData.list = res.data.news.list
      this.pageData.total = res.data.news.count
      this.pageData.text = res.data.text
    }
  }
}
</script>

<style lang="less" scoped>
.el-menu-vertical {
  background-color: unset;
  border: 0;
}
.carousel-image {
  width: 100%;
  height: 100%;
}
.carousel-text {
  width: 100%;
  color: #303133;
  font-size: 12px;
  opacity: 0.75;
  margin: 20px 0 0;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.box {
  display: flex;
}
.box .box-item {
  flex: 1;
  // padding: 5px;
}
.box-item .box-item-image {
  width: 100%;
  height: auto;
}
.box-item div {
  text-align: right;
}
.el-pagination {
  text-align: center;
}
.img-mark {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
