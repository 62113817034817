<template>
  <div v-if="serverSrc !== ''">
    <el-row type="flex" justify="space-between" class="hidden-xs-only">
      <el-col :span="2" class="hidden-xs-only" style="margin-left: 2.37%">
        <el-menu class="el-menu-vertical" :default-active="'/showRoom'" v-for="(item, i) in menuList" :key="i">
          <el-menu-item :index="'/' + item.path" @click="menuItemClick(i)">
            <span>{{ item.title }}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col style="width: 60.7%; margin-right: 2.29%" v-if="pageData.list.lenght !== 0">
        <ul>
          <li class="box">
            <span v-for="(item, i) in pageData.list" :key="item.index" class="box-item">
              <el-image class="box-item-image" :src="serverSrc + item.src" lazy @click="lookDetais(i)"></el-image>
              <div class="img-mark">{{ item.text }}</div>
            </span>
          </li>
        </ul>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[12]"
          :page-size="12"
          layout="prev, pager, next"
          :total="pageData.total"
          :hide-on-single-page="true"
        >
        </el-pagination>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" class="hidden-sm-and-up" style="padding: 45px 0 0; margin-bottom: 40px">
      <el-col :span="2" class="hidden-xs-only">
        <el-menu class="el-menu-vertical" :default-active="'/showRoom'" v-for="(item, i) in menuList" :key="i">
          <el-menu-item :index="'/' + item.path" @click="menuItemClick(i)">
            <span>{{ item.title }}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18" v-if="pageData.list.lenght !== 0">
        <ul>
          <li class="box">
            <span v-for="(item, i) in pageData.list" :key="i" class="box-item">
              <el-image class="box-item-image" :src="serverSrc + item.src" lazy @click="lookDetais(i)"></el-image>
              <div class="img-mark">{{ item.text }}</div>
            </span>
          </li>
        </ul>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[12]"
          :page-size="12"
          layout="prev, pager, next"
          :total="pageData.total"
          :hide-on-single-page="true"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      serverSrc: '',
      menuList: [],
      pageData: {
        page_sum: 1,
        total: 0,
        list: []
      },
      currentPage: 1
    }
  },
  created () {
    this.getMenuList()
    this.getPageData()
  },
  methods: {
    carouselChange (index) {
      this.carouseIndex = index
    },
    async getMenuList () {
      const { data: resData } = await this.$http.get('api/get_nav')
      const res = JSON.parse(resData)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.serverSrc = res.data.img_head_url
      this.menuList = res.data.nav
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.getPageData()
      // console.log(`当前页: ${val}`)
    },
    menuItemClick (index) {
      const item = this.menuList[index]
      if (item.type === 1) {
        this.$router.push('/' + item.path)
      } else if (item.type === 2) {
        this.$router.push({
          name: 'Show',
          query: {
            index: index
          }
        })
      }
    },
    async getPageData () {
      const { data: resData } = await this.$http.get('api/get_showroom', {
        params: { page: this.currentPage }
      })
      const res = JSON.parse(resData)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.pageData.list = res.data.list
      this.pageData.page_sum = res.data.page_sum
      this.pageData.total = res.data.count
    },
    lookDetais (index) {
      const bigSrc = this.pageData.list[index].bigSrc
      this.$router.push({
        name: 'Roomdetail',
        query: {
          bigSrc: bigSrc
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-menu-vertical {
  background-color: unset;
  border: 0;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.box {
  width: 100%;
  text-align: center;
  display: flex;
  align-content: flex-start;
  flex-flow: row wrap;
}
.box .box-item {
  display: inline-block;
  flex: 0 0 32%;
  margin-bottom: 20px;
}
.box .box-item:nth-child(3n + 1) {
  margin-right: 2%;
}
.box .box-item:nth-child(3n + 2) {
  margin-right: 2%;
}
// .box .box-item:nth-child(3n + 2) {
//   margin-right: 0.5%;
// }
// .box .box-item:nth-child(3n + 1) {
//   margin-right: 0.5%;
// }
.box-item .box-item-image {
  width: 100%;
  height: auto;
  cursor: pointer;
}
.el-image .box-item-image img {
  padding: 0 1.6% !important;
}
.box-item div {
  text-align: right;
}
.el-pagination {
  text-align: center;
}
.img-mark {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
